<template>
    <div>
        <CRow class="cardPersonalizado">
            <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div
                    class="cardHeaderPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos2"/></svg> <span style="margin-left:5px;"> {{ $t("label.SituacionBodega") }} </span>
                </div>
                <div style="width: 100%;padding:10px;">
                    <div class="d-flex align-items-center justify-content-end mb-2">
                        <span class="circle mr-1" style="background: #1A3760 !important;;"></span>{{ $t('label.planned') }}
                        <span class="circle mx-1" style="background: #EC631B !important;"></span>{{ $t('label.done') }}
                    </div>
                    <div class="d-flex">
                        <div class="flex-fill">
                            <div class="d-flex flex-row" style="background: #edebeb;padding:5px;">
                                <div class="p-2">
                                    <h6 style="margin-bottom: 0px;">ATA</h6>
                                    <b>{{ operationInfo.ata }}</b>
                                </div>
                                <div class="p-2">
                                    <h6 style="margin-bottom: 0px;">ETD</h6>
                                    <b>{{ operationInfo.etd }}</b>
                                </div>
                            </div>
                            <div>
                                <ColumnwithGroupLabel
                                    :textSize="'20px'"
                                    :seriesGrafica="seriesGrafica" 
                                    :groupsGrafica="groupsGrafica"
                                ></ColumnwithGroupLabel>
                            </div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { DateFormater } from '@/_helpers/funciones';
    import { mapState  } from 'vuex';
    import ColumnwithGroupLabel from '@/pages/situacion-operativa-actual/components/ColumnwithGroupLabel';
    //methods
    function valuemoney(value){
      if(value !== null){
        let modifiedValue = parseFloat(value).toFixed(2);
        return new Intl.NumberFormat("de-DE").format(modifiedValue);
      }
      return "0,00";
    }
    //computed
    function operationInfo(){
      let DataVessel = this.VesselSituationJson;
      let DataAtaEtd = this.itinerarySelected;
      if(Object.keys(DataVessel).length !== 0 && Object.keys(DataAtaEtd).length !== 0){
            return {
                ata: DataAtaEtd.Ata ? DateFormater.formatDateTimeWithSlash(DataAtaEtd.Ata) : 'N/A',
                etd: DataAtaEtd.Etd ? DateFormater.formatDateTimeWithSlash(DataAtaEtd.Etd) : 'N/A',
                ini: DataVessel.BeginOpsDate ? DateFormater.formatDateTimeWithSlash(DataVessel.BeginOpsDate) : 'N/A',
                end: DataVessel.EndOpsDate ? DateFormater.formatDateTimeWithSlash(DataVessel.EndOpsDate) : 'N/A',
                total: DataVessel.HoursOps ? DataVessel.HoursOps : 'N/A',
                totalProm: DataVessel.TonPromXImplement ? DataVessel.TonPromXImplement : 'N/A',
            };
        }else{
            return {
                ata:'N/A',
                etd:'N/A',
                ini:'N/A',
                end:'N/A',
                total:'N/A',
                totalProm:'N/A',
            };
        }
    }
    function seriesGrafica(){
        let data = [];
        if(this.HoldJson.length > 0){
            this.HoldJson.map(async(item,index) => {
                let PlannedTon =  item.PlannedTon;
                let EmbarkedTon = item.EmbarkedTon;
                data.push({
                    name: this.$t('label.planned'),
                    x : this.valuemoney(PlannedTon == null ? 0 : PlannedTon) + ' TON',
                    y: PlannedTon == null ? 0 : PlannedTon,
                    fillColor: "#013565"
                });
                data.push({
                    name: this.$t('label.done'),
                    x : this.valuemoney(EmbarkedTon == null ? 0 : EmbarkedTon) + ' TON',
                    y: EmbarkedTon == null ? 0 : EmbarkedTon,
                    fillColor: "#EC631B"
                });
            })
        }
        return data;
    }
    function groupsGrafica(){
        let data = [];
        if(this.HoldJson.length > 0){
            this.HoldJson.map(async(item,index) => {
                data.push({
                    title: item.VesselHoldName,
                    cols: 2
                });
            })
        }
        return data;
    }
    export default {
        name:'WarehouseSituation',
        props: {
            VesselSituationJson:{
                type: Object,
                default: function () {
                    return {};
                },
            },
            HoldJson:{
                type:Array,
                default: () => [],
            }
        },
        methods:{
            valuemoney
        },
        components:{
            ColumnwithGroupLabel
        },
        computed:{
            operationInfo,
            seriesGrafica,
            groupsGrafica,
            ...mapState({
                carruselTab : state=> state.situacionOperativaHistorico.carruselTab,
                itinerarySelected: state => state.situacionOperativaHistorico.itinerarySelected,
            }),
        }
    }   
</script>